import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { isDateString } from 'utils/dateHelper';
import { isObjectEmpty } from 'utils/objectHelper';

import { Preferences } from '../components/pages/Home/types/Preferences';
import { UnitsOfMeasurement } from '../types/units';
import { FormatNumberEnum } from '../types/user';
import logger from './logger';

export const formatISODate = (dateString: string, formatStr: string) => {
  try {
    const date = parseISO(dateString);
    return format(date, formatStr);
  } catch (error) {
    logger(`formatISODate error: dateString: ${error} - ${dateString}, formatStr: ${formatStr}`);
    return dateString;
  }
};

export const convertSqUnitsToPreferred = (
  preferredUnit: string,
  areaUnit: string,
  area: number,
  leaseAreaEnum: UnitsOfMeasurement
) => {
  return areaUnit !== preferredUnit
    ? preferredUnit === leaseAreaEnum.sqf.key && areaUnit === leaseAreaEnum.sqm.key
      ? area * 10.7639
      : area * 0.092903
    : area;
};

export const formatAmountBasedOnLocale = (
  amount: number | string,
  decimals: number = 0,
  formatNumber?: FormatNumberEnum.COMMA | FormatNumberEnum.PERIOD,
  useFinancialNegativeSign = false
) => {
  const local = formatNumber === FormatNumberEnum.PERIOD ? 'de-DE' : 'en-US';
  let num = typeof amount === 'string' ? parseFloat(amount) : amount;

  if (isNaN(num) || num === null) {
    num = 0;
  }

  const formatAsFinancialNegative = useFinancialNegativeSign && num < 0;
  if (formatAsFinancialNegative) num = Math.abs(num);

  const options = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };
  const formattedNumber = num.toLocaleString(local, options);
  return formatAsFinancialNegative ? `(${formattedNumber})` : formattedNumber;
};

export const calculateCurrencyConversionRate = (
  preferences: Preferences,
  sourceCurrency: string
) => {
  if (sourceCurrency === preferences.currency) return 1;

  const exchanges = preferences.exchanges;
  const sourceExchange = exchanges?.find((item) => item.currencyCode === sourceCurrency);
  const targetExchange = exchanges?.find((item) => item.currencyCode === preferences.currency);
  const sourceRate = sourceExchange?.exchangeRate ?? 1;
  const targetRate = targetExchange?.exchangeRate ?? 1;

  return targetRate / sourceRate;
};

export const convertCurrency = (
  preferences: Preferences,
  amount: number,
  amountCurrencyCode: string
) => {
  const rate = calculateCurrencyConversionRate(preferences, amountCurrencyCode);

  const amountInTargetCurrency = amount * rate;
  return amountInTargetCurrency;
};

export const formatAndConvertCurrencyWithLocale = (
  preferences: Preferences,
  amount: number,
  amountCurrencyCode: string,
  decimals = 0
) => {
  const amountInTargetCurrency = convertCurrency(preferences, amount, amountCurrencyCode);
  return formatAmountBasedOnLocale(amountInTargetCurrency, decimals, preferences.formatNumber);
};

export const removeTimeFromDate = (date: string): string => date.slice(0, 10);

export const formatDateWithoutTimeZone = (dateString: string, formatString: string): string => {
  const [year, month, day] = dateString.split('-');
  switch (formatString) {
    case 'MM/dd/yyyy':
      return `${month}/${day}/${year}`;
    case 'dd/MM/yyyy':
      return `${day}/${month}/${year}`;
    case 'dd-MMM-yyyy':
      return `${day}-${new Date(dateString).toLocaleString('en-US', {
        month: 'short',
        timeZone: 'UTC',
      })}-${year}`;
    default:
      return `${dateString}, formatString: ${formatString}`;
  }
};

export const formatDateToStringWithoutTimezone = (date: Date): string => {
  const [year, month, day] = date.toISOString().split('-');
  return `${year}-${month}-${day}`;
};

export const formatDateBasedOnPreferences = (
  preferences: Preferences,
  date: string | null | undefined | Date = '',
  returnFormat = ''
): string => {
  if (!date) return returnFormat;
  const dateString = date instanceof Date ? formatDateToStringWithoutTimezone(date) : date;
  const dateWithoutTime = removeTimeFromDate(dateString);
  return formatDateWithoutTimeZone(dateWithoutTime, preferences?.formatDate || 'MM/dd/yyyy');
};

const formatDate = (date: Date, formatStr: string): string => {
  return format(date, formatStr);
};

export const getFormattedDateAndTimeWithDashActivity = (
  date: string,
  preferences?: Preferences,
  todayText?: string,
  yesterdayText?: string
): string => {
  const newDate = new Date(date);
  let dateWithTimezone = newDate;
  let formattedDate;

  if (preferences?.timezone) dateWithTimezone = toZonedTime(newDate, preferences.timezone);
  if (preferences)
    formattedDate = formatDateBasedOnPreferences(preferences, dateWithTimezone.toISOString());
  else format(parseISO(date), 'dd-MMM-yyyy');
  const formattedTime = formatDate(dateWithTimezone, 'h:mm a');
  if (isToday(dateWithTimezone)) {
    return `${todayText} - ${formattedTime}`;
  }
  if (isYesterday(dateWithTimezone)) {
    return `${yesterdayText} - ${formattedTime}`;
  }
  return `${formattedDate} - ${formattedTime}`;
};

export const convertToFloatIfString = (value?: string | number): number | undefined => {
  return typeof value === 'string' ? parseFloat(value) : value;
};

const generateValueType = (
  valueName: string,
  value: string | null | undefined | object | number
) => {
  const valueTypeMapping: { [key: string]: string } = {
    escalationRate: 'escalationRate',
  };

  const valueType = valueTypeMapping[valueName] || typeof value;
  if (isDateString(String(value))) return 'date';

  return valueType;
};

export const formatFieldBasedOnPreferences = (
  field: string,
  value: string | null | undefined | object | number,
  preferences: Preferences
): string => {
  const emptySign = '-';
  const valueFormatters: {
    [key: string]: (value: string | null | undefined | object | number) => string;
  } = {
    null: () => emptySign,
    undefined: () => emptySign,
    //@ts-ignore
    object: (val) => (isObjectEmpty(val) ? emptySign : JSON.stringify(val)),
    number: (val) => String(val),
    //@ts-ignore
    date: (val) => formatDateBasedOnPreferences(preferences, val),
    //@ts-ignore
    escalationRate: (val: string | undefined): string => {
      if (val === undefined) {
        return '';
      }
      //@ts-ignore
      if (!Object.hasOwn(val, 'at') || val.at(-1) === '%') {
        return val;
      }
      return `${val}%`;
    },
    default: (val) => String(val),
  };

  const valueType = generateValueType(field, value);
  const valueFormatter = valueFormatters[valueType] || valueFormatters.default;
  return valueFormatter(value);
};
